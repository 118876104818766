import React from 'react'

function Nayama(w) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Isolation Mode"
      viewBox="0 0 745.25 107.93"
      width={w.w}
      style={w.s}
    >
      <path
        d="M602.79 493.16v53.23h-15.41v-77.14h13.87L649 522.48v-53.23h15.41v77.14h-13.85zM792.28 517.09h-16.64l12 29.3h16.64z"
        className="cls-1"
        transform="translate(-587.38 -438.85)"
        fill={w.c1}
      ></path>
      <path
        d="M162.22 30.39L178.5 30.39 146.97 107.54 130.62 107.54 162.22 30.39z"
        className="cls-1"
        fill={w.c1}
      ></path>
      <path
        d="M1036.88 517.09h-16.64l12 29.3h16.65z"
        className="cls-1"
        transform="translate(-587.38 -438.85)"
        fill={w.c1}
      ></path>
      <path d="M423.1 30.39L391.56 107.54 375.21 107.54 406.82 30.39 423.1 30.39z" className="cls-1" fill={w.c1}></path>
      <path
        d="M335.12 30.39L302.77 80.55 302.77 107.54 287.36 107.54 287.36 80.55 255.01 30.39 272.73 30.39 295 64.52 317.25 30.39 335.12 30.39z"
        className="cls-1"
        fill={w.c1}
      ></path>
      <path d="M686.33 0H727.34V13.95H686.33z" className="cls-1" fill={w.c2}></path>
      <path
        d="M1103.11 469.88h18.67l26.89 33.12 26.89-33.13h18.54v76.91h-16.21v-55.72l-28 33.35h-3.19L1119.32 491v55.84h-16.21zM1320.62 517.09H1304l12 29.3h16.64z"
        className="cls-1"
        transform="translate(-587.38 -438.85)"
        fill={w.c2}
      ></path>
      <path
        d="M706.84 30.39L675.3 107.54 658.95 107.54 690.55 30.39 706.84 30.39z"
        className="cls-1"
        fill={w.c2}
      ></path>
    </svg>
  )
}

export default Nayama
