const website = require('./website')

module.exports = {
  'es-mx': {
    default: true,
    path: 'es',
    locale: 'es-mx',
    siteLanguage: 'es',
    ogLang: 'es_MX',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: 'La autenticidad del lujo natural',
    headline: 'NAYA',
    category: 'Categoria',
    categories: 'Categorias',
    was: '',
    were: '',
    tagged: '',
    recent: '',
    projects: 'Proyectos',
    allCategories: 'Todas las categorias',
    entries: 'Entradas',
  },
  'en-us': {
    path: 'en',
    locale: 'en-us',
    siteLanguage: 'en',
    ogLang: 'en_US',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    headline: website.headline,
    category: 'Category',
    categories: 'Categories',
    was: 'was',
    were: 'were',
    tagged: 'tagged with',
    recent: 'Recent',
    projects: 'projects',
    allCategories: 'All categories',
    entries: 'entries',
  },
}
