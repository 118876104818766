module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'NAYA', // Navigation and Site Title
  titleAlt: 'la autenticidad del lujo natural', // Title for JSONLD
  description:
    'NAYA, emerge de la profundidad de la selva. Desde la vegetación se entrevera el brillo de la arena que da entrada a la inmensidad del océano Pacífico.',
  headline: 'NAYA by One Development Group', // Headline for schema.org JSONLD
  url: 'https://naya.mx/', // Domain of your site. No trailing slash!
  logo: '/logos/logo-1024.png', // Used for SEO
  ogLanguage: 'es_MX', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'NAYA', // shortname for manifest. MUST be shorter than 12 characters
  author: 'One Development Group', // Author for schemaORGJSONLD
  themeColor: '#00594E',
  backgroundColor: '#EBEDF2',

  twitter: '@', // Twitter Username
  facebook: 'naya.mx', // Facebook Site Name
  googleAnalyticsID: 'GTM-MS8HMJC',

  skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
