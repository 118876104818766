// extracted by mini-css-extract-plugin
export var active = "global-module--active--ae360";
export var amenidades = "global-module--amenidades--c82ff";
export var architecture = "global-module--architecture--c52b5";
export var architectureImage = "global-module--architectureImage--3ca3f";
export var architectureWrapper = "global-module--architectureWrapper--3c8bc";
export var bgSquare = "global-module--bgSquare--a2427";
export var bottom = "global-module--bottom--5f468";
export var bottomSquare = "global-module--bottomSquare--15c73";
export var bucerias = "global-module--bucerias--46f9b";
export var burger = "global-module--burger--fa82f";
export var buttonBack = "global-module--buttonBack--f30ce";
export var buttonCta = "global-module--buttonCta--96823";
export var centered = "global-module--centered--2274b";
export var circle = "global-module--circle--3d4f8";
export var circlenayama = "global-module--circlenayama--cbde4";
export var closed = "global-module--closed--7fe30";
export var colabButton = "global-module--colabButton--79c8b";
export var contactHero = "global-module--contactHero--cc9c7";
export var contactInfo = "global-module--contactInfo--0fe91";
export var contentSpan = "global-module--contentSpan--79ff8";
export var cosmoDesc = "global-module--cosmoDesc--a6e3d";
export var creatorSection = "global-module--creatorSection--de517";
export var cruz = "global-module--cruz--f45ad";
export var ctaButton = "global-module--ctaButton--31d51";
export var ctaColab = "global-module--ctaColab--d0c96";
export var ctaCreators = "global-module--ctaCreators--f1927";
export var descImage = "global-module--descImage--4ca4a";
export var description = "global-module--description--674dc";
export var descriptionMap = "global-module--descriptionMap--8859f";
export var destiny = "global-module--destiny--2933f";
export var experience = "global-module--experience--356f9";
export var experienceImage = "global-module--experienceImage--af9ba";
export var experienceImageWrap = "global-module--experienceImageWrap--b0b95";
export var footerList = "global-module--footerList--02efd";
export var gradient = "global-module--gradient--888da";
export var heroimage = "global-module--heroimage--eea63";
export var higuera = "global-module--higuera--5426c";
export var icon = "global-module--icon--e9307";
export var imageCont = "global-module--imageCont--bbaf4";
export var imageCreator = "global-module--imageCreator--cc791";
export var imageWrap = "global-module--imageWrap--01efa";
export var imgPlanos = "global-module--imgPlanos--d5e97";
export var infoDetails = "global-module--infoDetails--26375";
export var infoDetailsInner = "global-module--infoDetailsInner--d5e19";
export var invert = "global-module--invert--55347";
export var invertedBu = "global-module--invertedBu--4b9be";
export var labelMap = "global-module--labelMap--448a5";
export var labelMapNayama = "global-module--labelMapNayama--4383d";
export var location = "global-module--location--62387";
export var locationEl = "global-module--locationEl--0a47f";
export var map = "global-module--map--59819";
export var mapContact = "global-module--mapContact--88575";
export var mapImage = "global-module--mapImage--4c643";
export var mapWrap = "global-module--mapWrap--e4899";
export var mar = "global-module--mar--4e5e9";
export var markzeff = "global-module--markzeff--53647";
export var master = "global-module--master--513ae";
export var minimap = "global-module--minimap--e504c";
export var navControl = "global-module--navControl--ae3b0";
export var naya = "global-module--naya--068ae";
export var nayama = "global-module--nayama--d08bc";
export var noVisible = "global-module--noVisible--17bd0";
export var oneFooter = "global-module--oneFooter--fcdf1";
export var openModal = "global-module--openModal--b07c7";
export var opened = "global-module--opened--20a20";
export var order2 = "global-module--order-2--5f634";
export var order3 = "global-module--order-3--d0cb1";
export var pattern = "global-module--pattern--483f9";
export var patternBg = "global-module--patternBg--50edc";
export var patternTop = "global-module--patternTop--aeee3";
export var patternWrapper = "global-module--patternWrapper--8d010";
export var plano = "global-module--plano--d07c0";
export var playa = "global-module--playa--842e4";
export var playaColor = "global-module--playaColor--00225";
export var puntademita = "global-module--puntademita--0352c";
export var puntamita = "global-module--puntamita--28c9a";
export var residenceView = "global-module--residenceView--40e61";
export var rightSlogan = "global-module--right-slogan--3ddb6";
export var sectionColabs = "global-module--sectionColabs--36769";
export var selva = "global-module--selva--b363f";
export var show = "global-module--show--466aa";
export var showText = "global-module--show-text--30462";
export var spin = "global-module--spin--bd4d2";
export var splitLetters = "global-module--split-letters--39ae8";
export var st0 = "global-module--st0--72674";
export var textAmenities = "global-module--textAmenities--d11ce";
export var textColab = "global-module--textColab--2bf9a";
export var textContact = "global-module--textContact--86562";
export var textCrea = "global-module--textCrea--b40a3";
export var textCreators = "global-module--textCreators--9a093";
export var theImage = "global-module--theImage--db6ba";
export var titleArqui = "global-module--titleArqui--46245";
export var titleMaster = "global-module--titleMaster--5af1a";
export var title_moment = "global-module--title_moment--de7da";
export var top = "global-module--top--348a6";
export var topSquare = "global-module--topSquare--9cb68";
export var unZoom = "global-module--unZoom--5179b";
export var wixa = "global-module--wixa--9375e";
export var wixaImage = "global-module--wixaImage--570f2";
export var wixaImageWrapper = "global-module--wixaImageWrapper--31559";
export var wixaVectorWrapper = "global-module--wixaVectorWrapper--425a8";
export var wrapNav = "global-module--wrapNav--275b3";
export var zoomI = "global-module--zoomI--0d5ba";
export var zoomed = "global-module--zoomed--630b9";