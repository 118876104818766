exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-categories-jsx": () => import("./../../../src/pages/categories.jsx" /* webpackChunkName: "component---src-pages-categories-jsx" */),
  "component---src-pages-colaboradores-jsx": () => import("./../../../src/pages/colaboradores.jsx" /* webpackChunkName: "component---src-pages-colaboradores-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-masterplan-jsx": () => import("./../../../src/pages/masterplan.jsx" /* webpackChunkName: "component---src-pages-masterplan-jsx" */),
  "component---src-pages-masterplan-nayama-jsx": () => import("./../../../src/pages/masterplan-nayama.jsx" /* webpackChunkName: "component---src-pages-masterplan-nayama-jsx" */),
  "component---src-pages-preventa-jsx": () => import("./../../../src/pages/preventa.jsx" /* webpackChunkName: "component---src-pages-preventa-jsx" */),
  "component---src-pages-tour-virtual-jsx": () => import("./../../../src/pages/tour-virtual.jsx" /* webpackChunkName: "component---src-pages-tour-virtual-jsx" */),
  "component---src-pages-zona-jsx": () => import("./../../../src/pages/zona.jsx" /* webpackChunkName: "component---src-pages-zona-jsx" */)
}

