import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { ThemeProvider } from 'emotion-theming'
import Helmet from 'react-helmet'
import LogoOne from "./LogoOne"
import * as styles from "../styles/global.module.css"
import { LocaleContext } from "./Layout"
import Nayama from './Nayama'

const StyledFooter = styled.footer`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto 0 auto;
  padding: 4rem 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.16);
  background: #502c1e;
  color: ${props => props.theme.colors.grey};
`

const Footer = ({children, locale, location}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <StyledFooter lang={locale}>
      <div className="grid-container grid-x">
        <div className="large-6 medium-6 small-6 text-left" style={{marginBottom: '45px'}}>
          <svg version="1.1" x="0px" y="0px" width="71px" height="12px" viewBox="0 0 71 12" style={{enableBackground:'new 0 0 71 12'}}>
            <g>
              <path className={styles.st0} d="M2.37,3.72V12H0V0h2.13l7.35,8.28V0h2.37v12H9.72L2.37,3.72z"/>
              <g>
                <path className={styles.st0} d="M31.52,7.44h-2.56L30.81,12h2.56L31.52,7.44z"/>
                <polygon className={styles.st0} points="24.96,0 27.46,0 22.61,12 20.09,12"/>
              </g>
              <path className={styles.st0} d="M69.15,7.44h-2.56L68.44,12H71L69.15,7.44z"/>
              <polygon className={styles.st0} points="65.09,0 60.24,12 57.72,12 62.58,0 	"/>
              <polygon className={styles.st0} points="51.56,0 46.58,7.8 46.58,12 44.21,12 44.21,7.8 39.23,0 41.95,0 45.38,5.31 48.81,0 	"/>
            </g>
          </svg>
          <Nayama w="121px" s={{ marginTop: '-5px', marginLeft: '20px' }} c1="#fff" c2="#fff" />
        </div>
        <div className="large-6 medium-6 small-6 text-right">
          <div className={styles.oneFooter}>
            <LogoOne/>
          </div>
        </div>

        <div className={`${styles.footerList  } large-8 medium-8 hide-for-small-only`}>
          <ul>
            <li style={{color: '#fff'}}><Link to={locale === 'es-mx' ? ( '/masterplan' ) : ( '/en/masterplan' )}>Master plan Naya</Link> <Link to={locale === 'es-mx' ? ( '/masterplan-nayama' ) : ( '/en/masterplan-nayama' )} >Master plan NAYAMĀ</Link></li>
            <li><Link to={locale === 'es-mx' ? ( '/colaboradores' ) : ( '/en/colaboradores' )}>{locale === 'es-mx' ? ( 'Colaboradores' ) : ( 'Associates' )}</Link></li>
            <li><Link to={locale === 'es-mx' ? ( '/zona' ) : ( '/en/zona' )}>{locale === 'es-mx' ? ( 'Espíritu de la zona' ) : ( 'Spirit of the area' )}</Link></li>
            <li><Link to={locale === 'es-mx' ? ( '/contacto' ) : ( '/en/contacto' )}>{locale === 'es-mx' ? ( 'Contacto' ) : ( 'Contact' )}</Link></li>
          </ul>
        </div>
        <div className={`${styles.footerList  } large-4 large-4 small-12 text-right`}>
          <ul>
            <li><Link to="/aviso-privacidad" style={{fontWeight: '300'}}>{locale === 'es-mx' ? ( 'Política de privacidad' ) : ( 'Terms and Privacy' )}</Link></li>
          </ul>
        </div>
      </div></StyledFooter>
  )
}

export default Footer

const query = graphql`
  query FooterQuery {
    allPrismicHomepage {
      edges {
        node {
          lang
          data {
            footer {
              html
            }
          }
        }
      }
    }
  }
`

Footer.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
}
