import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Global, css } from '@emotion/core'
import styled from '@emotion/styled'
import { ThemeProvider } from 'emotion-theming'
import '@reach/skip-nav/styles.css'
import * as styles from '../styles/global.module.css'
import Footer from './Footer'
import SkipNavLink from './SkipNavLink'
import { theme, reset } from '../styles'
import i18n from '../../config/i18n'
import '../styles/mapa.css'

// import Navbar from './Navbar'

// import Smooth from 'smooth-scrolling'

// if (typeof document !== `undefined`) {
//   // import Smooth from 'smooth-scrolling'
//   const Smooth = require('smooth-scrolling')
// }

import 'typeface-lora'
import 'typeface-source-sans-pro'

import 'foundation-sites/dist/css/foundation.min.css'
import Nayama from './Nayama'

// import * as styles from "../styles/global.module.css"

const globalStyle = css`
  ${reset}
  .is-virtual-scroll {
    overflow: hidden;
  }

  .is-native-scroll.y-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .is-native-scroll.x-scroll {
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .vs-section {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: auto;
    margin: auto;
    will-change: transform;
  }

  .vs-scrollbar {
    display: block;
    position: absolute;
    transition: transform 0.6s;
  }

  .vs-scrollbar.vs-vertical {
    top: 0;
    right: -5px;
    bottom: 0;
    width: 15px;
    height: 100%;
    transform: translate3d(5px, 0, 0);
  }

  .vs-scrollbar.vs-horizontal {
    bottom: -5px;
    left: 0;
    right: 0;
    width: 100%;
    height: 15px;
    transform: translate3d(0, 5px, 0);
  }

  .is-dragging .vs-scrollbar.vs-horizontal,
  .is-dragging .vs-scrollbar.vs-vertical,
  .vs-scrollbar.vs-horizontal:hover,
  .vs-scrollbar.vs-vertical:hover {
    transform: none;
  }

  .vs-scrollbar .vs-scrolldrag {
    width: 100%;
    height: auto;
    background: #ccc;
    cursor: pointer;
  }

  .vs-scroll-view {
    position: relative;
    width: 1px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.colors.black};
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    color: ${theme.colors.greyDarker};
    background-color: ${theme.colors.bg};
  }
  ::selection {
    color: ${theme.colors.bg};
    background-color: ${theme.colors.primary};
  }
  a {
    color: ${theme.colors.primary};
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    font-weight: 700;
    font-style: italic;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  @media (max-width: ${theme.breakpoints.m}) {
    html {
      font-size: 16px !important;
    }
  }
  @media (max-width: ${theme.breakpoints.s}) {
    h1 {
      font-size: 2.369rem !important;
    }
    h2 {
      font-size: 1.777rem !important;
    }
    h3 {
      font-size: 1.333rem !important;
    }
    h4 {
      font-size: 1rem !important;
    }
    h5 {
      font-size: 0.75rem !important;
    }
    h6 {
      font-size: 0.563rem !important;
    }
  }

  .dul {
    justify-content: center;
    align-items: flex-start;
    ul {
      padding: 0 10px;

      li:first-child {
        font-size: 0.9rem !important;
        font-weight: 600;
        margin-top: 10px;
      }
    }
  }
`

const Portal = styled.section`
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;

  &.modal-open {
    display: block;
  }
`

const LocaleSwitcher = styled.div`
  position: absolute;
`

const LocaleContext = React.createContext()

// class ScrollJS extends React.Component {
//     componentDidMount() {
//     setTimeout(function(){
//       window.dispatchEvent(new Event('resize'));
//     }, 2000);

//  /* These are the modifications: */
// history.pushState = ( f => function pushState(){
//     var ret = f.apply(this, arguments);
//     window.dispatchEvent(new Event('pushstate'));
//     window.dispatchEvent(new Event('locationchange'));
//     setTimeout(function(){
//       window.dispatchEvent(new Event('resize'));
//     }, 1000);

//     return ret;
// })(history.pushState);

// history.replaceState = ( f => function replaceState(){
//     var ret = f.apply(this, arguments);
//     window.dispatchEvent(new Event('replacestate'));
//     window.dispatchEvent(new Event('locationchange'));
//     setTimeout(function(){
//       window.dispatchEvent(new Event('resize'));
//     }, 1000);

//     return ret;
// })(history.replaceState);

// window.addEventListener('popstate',()=>{
//     window.dispatchEvent(new Event('locationchange'))
//     setTimeout(function(){
//       window.dispatchEvent(new Event('resize'));
//     }, 1000);

// });
//       if ("ontouchstart" in document.documentElement) {
//         var element = document.querySelector('.wrap')
//         element.classList.remove('vs-section');

//         document.getElementsByTagName("body")[0].style.overflow = 'scroll'
//       } else {
//       var element = document.querySelector('.wrap')
//       // const opacity = document.querySelector('.vs-opacity')

//       const section = document.querySelector('.vq')

//       var smooth = new Smooth({
//         section: section,
//         ease: 0.1,
//         native: true,
//         preload: true,
//       })

//       smooth.init();
//       }
//     }
//   render() {
//     return (
//       <div>
//       </div>
//       // Nothing to show mi compa.
//     );
//   }
// }

const Nav = styled.nav`
  width: 100%;
  z-index: 9999;
  position: fixed;
  background: rgba(250, 250, 246, 0.95);
  padding: 9px 25px 10px 25px;

  .alignment {
    position: relative;
  }

        @media screen and (max-width: 720px) {
            .logo {
              transform: scale(0.7);
            }

            .switcher {
              left: inherit !important;
              right: 0px !important;
            }
        }
`

class Burger extends Component {
  toggleClass = () => {
    const oldClassName = document.getElementById('nav').className
    const newClassName = oldClassName === `${styles.closed}` ? `${styles.opened}` : `${styles.closed}`
    document.getElementById('nav').className = newClassName

  }

  render() {
    return (
      <a
        href="#"
        onClick={e => {
          this.toggleClass(e)
          e.preventDefault()
        }}
        className={styles.openModal}
      >
        <div className={styles.burger}>
          <svg
            version="1.1"
            x="0px"
            y="0px"
            width="18px"
            height="22px"
            viewBox="0 0 18 12"
            style={{ enableBackground: 'new 0 0 18 12' }}
          >
            <g>
              <rect
                x="7.8"
                y="1.8"
                transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 19.7979 1.7979)"
                width="2.4"
                height="18"
              />

              <rect
                x="7.8"
                y="-7.8"
                transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 10.1997 -7.8003)"
                width="2.4"
                height="18"
              />
              <rect x="7.8" y="-3" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 15 -3)" width="2.4" height="18" />
            </g>
          </svg>
        </div>
      </a>
    )
  }
}

const Layout = ({ children, pageContext: { locale } }) => {
  const data = useStaticQuery(query)
  const footer = data.allPrismicHomepage.edges
    .filter(edge => edge.node.lang === locale)
    .map(r => r.node.data.footer.html)
    .toString()

  class Navigation extends Component {
    constructor(props) {
      super(props)
      this.state = { addClass: false }
    }

    toggle() {
      this.setState({ addClass: !this.state.addClass })
    }

    
    render() {

      return (
        <Nav>
          <div id="nav" className={this.state.addClass ? styles.opened : styles.closed}>
            <div className="alignment align-middle text-center">
            <div
                onClick={e => {
                  this.toggle()
                }}
                className={styles.openModal}
              >
                <div className={styles.burger}>
                  <svg
                    version="1.1"
                    x="0px"
                    y="0px"
                    width="18px"
                    height="22px"
                    viewBox="0 0 18 12"
                    style={{ enableBackground: 'new 0 0 18 12' }}
                  >
                    <g>
                      <rect
                        x="7.8"
                        y="1.8"
                        transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 19.7979 1.7979)"
                        width="2.4"
                        height="18"
                      />

                      <rect
                        x="7.8"
                        y="-7.8"
                        transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 10.1997 -7.8003)"
                        width="2.4"
                        height="18"
                      />
                      <rect x="7.8" y="-3" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 15 -3)" width="2.4" height="18" />
                    </g>
                  </svg>
                </div>
              </div>

              <LocaleSwitcher
                data-name="locale-switcher"
                style={{
                  position: 'absolute',
                  left: '30px',
                  fontSize: '0.85rem',
                  paddingTop: '6px',
                  textTransform: 'uppercase',
                  color: 'rgba(0,0,0,0.4)',
                }}
                className='switcher'
              >
                <Link hrefLang="es-mx" to="/" style={{ color: '#00594e' }}>
                  ES
                </Link>{' '}
                /{' '}
                <Link hrefLang="en-us" to="/en" style={{ color: '#00594e' }}>
                  EN
                </Link>
              </LocaleSwitcher>

              <div className="logo">
                <div onClick={e => {
                  this.toggle()
                }}>
                  <svg
                    version="1.1"
                    x="0px"
                    y="0px"
                    width="91px"
                    height="32px"
                    viewBox="0 0 71 12"
                    style={{ enableBackground: 'new 0 0 71 12' }}
                  >
                    <g>
                      <path className={styles.st0} d="M2.37,3.72V12H0V0h2.13l7.35,8.28V0h2.37v12H9.72L2.37,3.72z" />
                      <g>
                        <path className={styles.st0} d="M31.52,7.44h-2.56L30.81,12h2.56L31.52,7.44z" />
                        <polygon className={styles.st0} points="24.96,0 27.46,0 22.61,12 20.09,12" />
                      </g>
                      <path className={styles.st0} d="M69.15,7.44h-2.56L68.44,12H71L69.15,7.44z" />
                      <polygon className={styles.st0} points="65.09,0 60.24,12 57.72,12 62.58,0   " />
                      <polygon
                        className={styles.st0}
                        points="51.56,0 46.58,7.8 46.58,12 44.21,12 44.21,7.8 39.23,0 41.95,0 45.38,5.31 48.81,0  "
                      />
                    </g>
                  </svg>
                  <span
                    style={{
                      color: '#595C62',
                      fontWeight: 'normal',
                      fontStyle: 'normal',
                      verticalAlign: 'text-top',
                      marginLeft: '20px',
                      marginRight: '10px'
                    }}
                  >
                    |
                  </span>
                  <Nayama w="141px" s={{ marginTop: '-5px', marginLeft: '10px' }} c1="#956E41" c2="#502C1E" />
                </div>
              </div>
              <div className={`${styles.rightSlogan} hide-for-small-only`}>
                <p>RIVIERA NAYARIT,</p>
                <p>PUNTA DE MITA — MÉXICO</p>
              </div>
            </div>

            <div id={styles.navControl}>
              <div className="grid-container grid-x text-center align-center align-middle" style={{ height: '90%' }}>
                <div className={`${styles.wrapNav} large-12 medium-12 small-12`}>
                  <ul>
                    <li>
                      <Link to={locale === 'es-mx' ? '/' : '/en/'} onClick={this.toggle.bind(this)}>
                        {locale === 'es-mx' ? 'Principal' : 'Home'}
                      </Link>
                    </li>

                    <li>
                      <Link
                        to={locale === 'es-mx' ? '/masterplan/' : '/en/masterplan/'}
                        onClick={this.toggle.bind(this)}
                      >
                        Master Plan
                      </Link>
                    </li>
                    <ul className='dul grid-x'>
                      <ul>
                        <li>Naya</li>
                        <li>
                          <Link
                            to={locale === 'es-mx' ? '/masterplan/' : '/en/masterplan/'}
                            onClick={this.toggle.bind(this)}
                          >
                            {locale === 'es-mx' ? 'Residencias' : 'Residences'}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              locale === 'es-mx'
                                ? `/masterplan/#mastermap${styles.architecture}`
                                : `/en/masterplan/#${styles.architecture}`
                            }
                            onClick={this.toggle.bind(this)}
                          >
                            {locale === 'es-mx' ? 'Arquitectura y Diseño' : 'Architecture & Design'}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              locale === 'es-mx'
                                ? `/masterplan/#mastermap${styles.amenidades}`
                                : `/en/masterplan/#${styles.amenidades}`
                            }
                            onClick={this.toggle.bind(this)}
                          >
                            {locale === 'es-mx' ? 'Amenidades' : 'Amenities'}
                          </Link>
                        </li>
                      </ul>
                      <ul>
                        <li>NAYAMĀ</li>
                        <li>
                          <Link
                            to={locale === 'es-mx' ? '/masterplan-nayama/' : '/en/masterplan-nayama/'}
                            onClick={this.toggle.bind(this)}
                          >
                            {locale === 'es-mx' ? 'Residencias' : 'Residences'}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              locale === 'es-mx'
                                ? `/masterplan-nayama/#mastermap${styles.architecture}`
                                : `/en/masterplan-nayama/#${styles.architecture}`
                            }
                            onClick={this.toggle.bind(this)}
                          >
                            {locale === 'es-mx' ? 'Arquitectura y Diseño' : 'Architecture & Design'}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              locale === 'es-mx'
                                ? `/masterplan-nayama/#mastermap${styles.amenidades}`
                                : `/en/masterplan-nayama/#${styles.amenidades}`
                            }
                            onClick={this.toggle.bind(this)}
                          >
                            {locale === 'es-mx' ? 'Amenidades' : 'Amenities'}
                          </Link>
                        </li>
                      </ul>

                    </ul>

                    <li>
                      <Link
                        to={locale === 'es-mx' ? '/colaboradores' : '/en/colaboradores'}
                        onClick={this.toggle.bind(this)}
                      >
                        {locale === 'es-mx' ? 'Colaboradores' : 'Associates'}
                      </Link>
                    </li>
                    <ul>
                      <li>
                        <Link
                          to={locale === 'es-mx' ? '/colaboradores#one' : '/en/colaboradores#one'}
                          onClick={this.toggle.bind(this)}
                        >
                          ONE DEVELOPMENT GROUP
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={locale === 'es-mx' ? '/colaboradores#jsa' : '/en/colaboradores#jsa'}
                          onClick={this.toggle.bind(this)}
                        >
                          JSa
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={locale === 'es-mx' ? '/colaboradores#markzeff' : '/en/colaboradores#markzeff'}
                          onClick={this.toggle.bind(this)}
                        >
                          MARKZEFF
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={locale === 'es-mx' ? '/colaboradores#cadena' : '/en/colaboradores#cadena'}
                          onClick={this.toggle.bind(this)}
                        >
                          CADENA + ASOC.
                        </Link>
                      </li>
                    </ul>

                    <li>
                      <Link to={locale === 'es-mx' ? '/zona' : '/en/zona'} onClick={this.toggle.bind(this)}>
                        {locale === 'es-mx' ? 'Espiritu de la zona' : 'SPIRIT OF THE AREA'}
                      </Link>
                    </li>
                    <li>
                      <a href="/tour-virtual" target="_blank">
                        {locale === 'es-mx' ? 'Tour virtual' : 'Tour virtual'}
                      </a>
                    </li>
                    <li>
                      <Link to="/contacto" onClick={this.toggle.bind(this)}>
                        {locale === 'es-mx' ? 'Contacto' : 'Contact Us'}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Nav>
      )
    }
  }

  return (
    <LocaleContext.Provider value={{ locale, i18n }}>
      <ThemeProvider theme={theme}>
        <>
          <Global styles={globalStyle} />

          <Navigation />
          <SkipNavLink />
          {children}
          <Footer locale={locale}>
            <div dangerouslySetInnerHTML={{ __html: footer }} />
          </Footer>
          <Portal id="portal" />
        </>
      </ThemeProvider>
    </LocaleContext.Provider>
  )
}

export { LocaleContext, Layout }

const query = graphql`
  query LayoutQuery {
    allPrismicHomepage {
      edges {
        node {
          lang
          data {
            footer {
              html
            }
          }
        }
      }
    }
  }
`

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}
